import { Plugin } from 'vue'

import { SentryConfig } from '@ankor-io/sentry-conflux/src/types'

import { AuthenticationClientOptions } from '@/iam/types'

/**
 * The config type definition
 */

export declare type Config = {
  /**
   * Kinde client related configurations
   */
  AUTHENTICATION_CLIENT_OPTIONS: AuthenticationClientOptions
  /**
   * Algolia config setting relevant to the front end. Important: do not include any api keys here.
   */
  ALGOLIA: {
    /**
     * The algolia app id
     */
    app_id: string
    /**
     * The algolia geo key
     */
    geo_key: string
    /**
     * The algolia search key
     */
    search_key: string
  }
  /**
   * GROWTHBOOK config for creating a growthbook instance
   * Use the SDK connection since the endpoint has now been deprecated
   */
  GROWTHBOOK: {
    /**
     * Client key used for registration of SDK connection
     */
    clientKey: string
    /**
     * Whether to enable development mode
     */
    enableDevMode: boolean
  }
  /**
   * GTM config required to initialize GTM plugin
   */
  GTM: {
    /**
     * ID for the GTM Container
     */
    id: string
    /**
     * The preview id of the container
     */
    gtm_preview: string
    /**
     * The auth for GTM container
     */
    gtm_auth: string
  }

  STRIPE: {
    /**
     * Payment link for RA Website API
     */
    WEBSITE_PAYMENT_LINK: string
    /**
     * The stripe publishable key
     */
    STRIPE_PUBLISHABLE_KEY: string
    /**
     * The stripe customer portal link
     */
    STRIPE_CUSTOMER_PORTAL: string
    /**
     * The in-app purchase stripe pricing table
     */
    APP_PRICING_TABLE: string
  }
  /**
   * Sentry tracing settings
   */
  SENTRY: SentryConfig
  /**
   * The Stowage http endpoint url
   */
  STOWAGE_URL: string
  /**
   * The url to wave
   */
  WAVE_URL: string
}

// intersect our main type with a Vue app plugin for using it in the app.
export type AppConfig = Config & Plugin
export const ConfigKey: string = 'config'
